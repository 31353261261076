import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Swiper from "react-id-swiper"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import { testimonialCareer } from "../components/testimonialsCareerData"

import tesImg from "../images/new-images/Image6-min.jpg"

const ContactModal = props => {
  const { buttonLabel, item } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // console.log(item)

  return (
    <>
      <Button
        aria-label="btn"
        style={{
          background: "none",
          border: "none",
          padding: 0,
          textTransform: "capitalize",
        }}
        onClick={toggle}
      >
        {buttonLabel}
      </Button>

      <Modal
        centered={true}
        isOpen={modal}
        toggle={toggle}
        className="contactNewTesti"
      >
        <ModalHeader toggle={toggle}>
          {/* <h4 className="mb-0">{item.title}</h4> */}
        </ModalHeader>
        <ModalBody>
          {item.review}
          <br />
          <br />
          <h5 className="title">{item.reviewer}</h5>
          <h5 className="title mt-0">{item.position}</h5>
        </ModalBody>
      </Modal>
    </>
  )
}

function Testimonial() {
  // console.log(contactTestimonial)
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    containerClass: "swiper-container pb-5",
    nested: true,
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <section id="team-test" style={{ padding: "1rem 0 3rem" }}>
      <ScrollAnimation
        animateIn="fadeIn"
        initiallyVisible={false}
        animateOnce={true}
      >
        <div id="team-testimonial-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="testimonial-main-image">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/new-images/Image6.JPG"
                    alt="testimonial"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="mobile-testimonial-img">
                  <img
                    placeholder="blurred"
                    className="img-fluid"
                    src={tesImg}
                    alt="Testimonial Image"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="testimonial-swiper">
                  <div className="container">
                    <Swiper {...params}>
                      {testimonialCareer.map((item, idx) => {
                        const limitReview = item.review.substring(0, 250)

                        return (
                          <div key={idx} className="swiper-slide">
                            <div className="testimonial-item">
                              <div className="testimonial-item-inner border-shadow">
                                <div className="testimonial-header">
                                  {item.title && (
                                    <p className="h4">{item.title}</p>
                                  )}
                                </div>
                                <div className="testimonial-content newContent mt-5">
                                  <p>
                                    {limitReview}
                                    {limitReview.length >= 250 ? (
                                      <span>
                                        ...
                                        <ContactModal
                                          item={item}
                                          buttonLabel="Read More"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                                <div className="testimonial-holder">
                                  <div className="testimonial-meta mt-5">
                                    <p className="h5 title">{item.reviewer}</p>
                                    <p className="h5 title mt-0">
                                      {item.position}
                                    </p>
                                    <p className="company">{item.company}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </section>
  )
}

export default Testimonial
