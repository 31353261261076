export const testimonialCareer = [
  {
    title: "",
    review:
      "I've been fortunate to work with colleagues such as the ones I've found at Quandary. Everyone I've met here is intelligent and equally welcoming - it truly feels like I am amongst friends. I am encouraged to be my best self, and in turn - motivated to help my colleagues grow as well.",
    reviewer: "Luke N.",
    position: "Technical Account Manager",
    company: "",
  },
  {
    title: "",
    review:
      "Working at Quandary is a true delight for me. It's not just about the work-life balance and remote flexibility, but also the daily opportunities to expand my knowledge. What truly sets Quandary apart is the exceptional team I have the privilege of working with. Here, it's clear that my success is a collective goal, and that camaraderie makes every day fulfilling.",
    reviewer: "Chad O.",
    position: "Technical Project Manager",
    company: "",
  },
  {
    title: "",
    review:
      "Quandary Consulting Group is a great place to work because they care about their employees, the community, and work-life balance. I've been able to develop my skills, grow my career, and give back to the community, all while having the time to enjoy the things I love, like hiking.",
    reviewer: "Desiree E.",
    position: "Jr. Solution Consultant",
    company: "",
  },
  {
    title: "",
    review:
      "Working at Quandary has been an incredible experience in my career. Leadership is never hesitant to take feedback and implement changes to create a better work environment, and it's clear in all their actions that the drive is to improve employee experience as well as client experience; one never feels employees are being shortchanged in favor of growth, it's clear that labor is not a line on an expense sheet and is highly valued as an integral part of growing and sustaining the company. From the CEO to the most junior consultant, support and a positive, welcoming work environment are daily qualities that are apparent and keep me from ever imagining working anywhere else!",
    reviewer: "Christine G.",
    position: "Technical Account Manager",
    company: "",
  },
  {
    title: "",
    review:
      "Quandary has been an amazing landing zone for me in my IT career. I’ve enjoyed a rich and healthy work-life balance at Quandary, where there’s a team structure that fosters independence, career growth, and collaborative problem-solving techniques. Our clients know our quality of work and place trust in us as if we were members of their own team. Each day I experience a wonderful balance of close interpersonal connection and focused technical development work.",
    reviewer: "Ben N.",
    position: "Solution Consultant",
    company: "",
  },
  {
    title: "",
    review:
      "Quandary stands out due to the team, opportunities, and passion to be better. We have an incredible team that pulls together and delivers great solutions. Quandary offers many opportunities: working with exciting clients, working from home, growing professionally, and the chance to utilize your talents. A common trait among us at QCG is to always be improving be it through certifications, educational courses, or from one another. A favorite of mine is our monthly Community of Practice where individuals can present on different topics to our whole team.",
    reviewer: "Zack S.",
    position: "Development Consultant",
    company: "",
  },
  {
    title: "",
    review:
      "My experience at Quandary has been truly unique. Colleagues are more than just coworkers; they're collaborators, mentors, and friends who make each day enjoyable. Having a diverse range of clients mixed with a culture that emphasizes professional development, I have experienced tremendous professional growth. I'm fortunate to be surrounded by so many great people and am very excited for the road ahead!",
    reviewer: "Adam B.",
    position: "Technical Account Manager",
    company: "",
  },
  {
    title: "",
    review:
      "At Quandary, it’s been a joy to go to work every day. Besides allowing me to be creative in developing, QCG employs the best of the best in know-how and attitude. Need help or clueless about something? Everyone graciously lines up to help! Also, the flexibility with working remotely at QCG is exceptional, especially because I enjoy being an active parent at my kid's school.",
    reviewer: "Christin S.",
    position: "Solution Consultant",
    company: "",
  },
]
